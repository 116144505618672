import React from 'react'
import { Link } from 'react-router-dom';


const MachineHeader = (props) => {
	return (
		<>
			<li className="nav-item">
				<Link className="nav-link " aria-current="page" to={props.route}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						className="me-2"
						width="18"
						height="19"
						viewBox="0 0 18 19"
						fill="none"
					>
						<path
							d="M1 10.5H7C7.55 10.5 8 10.05 8 9.5V1.5C8 0.95 7.55 0.5 7 0.5H1C0.45 0.5 0 0.95 0 1.5V9.5C0 10.05 0.45 10.5 1 10.5ZM1 18.5H7C7.55 18.5 8 18.05 8 17.5V13.5C8 12.95 7.55 12.5 7 12.5H1C0.45 12.5 0 12.95 0 13.5V17.5C0 18.05 0.45 18.5 1 18.5ZM11 18.5H17C17.55 18.5 18 18.05 18 17.5V9.5C18 8.95 17.55 8.5 17 8.5H11C10.45 8.5 10 8.95 10 9.5V17.5C10 18.05 10.45 18.5 11 18.5ZM10 1.5V5.5C10 6.05 10.45 6.5 11 6.5H17C17.55 6.5 18 6.05 18 5.5V1.5C18 0.95 17.55 0.5 17 0.5H11C10.45 0.5 10 0.95 10 1.5Z"
							fill="#7F56D9"
						></path>
					</svg>
					Machines
				</Link>
			</li>
		</>
	);
}

export default MachineHeader
