"use client"

import { Tab, Tabs } from '@mui/material';
import { useEffect, useState } from "react";
import { CiBoxList } from "react-icons/ci";
import {
    FaArrowLeft, FaClock,
    FaLightbulb,
    FaStar,
    FaStopCircle,
    FaSyncAlt, FaUser
} from "react-icons/fa";
import { IoIosInformationCircle } from "react-icons/io";
import { LuActivity } from "react-icons/lu";
import { MdComputer } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { challenges } from "../ActiveMachines";
import "../machine.css";
export default function MachinesDashboard({ roleType }) {
    const { name } = useParams();
    const [selectedMode, setSelectedMode] = useState("guided")
    const [answer, setAnswer] = useState("")
    const [machine, setMachine] = useState("")
    const [activeTab, setActiveTab] = useState("activeMachine");
    const tabs = [
        { id: "activeMachine", label: "Active Machine", icon: <MdComputer /> },
        { id: "machineInfo", label: "Machine Info", icon: <IoIosInformationCircle /> },
        { id: "activity", label: "Activity", icon: <LuActivity /> },
        { id: "logs", label: "Logs", icon: <CiBoxList /> },
    ];

    const navigate = useNavigate();

    useEffect(() => {
        const data = challenges.find(ch => ch.name === name);
        setMachine(data);
    }, [name]);


    const handleNavigateMachine = () => {
        navigate(`/${roleType}/cyber-dashboard`);
    }

    return (
        <div className="min-vh-100" style={{ backgroundColor: '#fff', color: '#333' }}>
            {/* Top navigation bar */}
            <div className="d-flex align-items-center justify-content-between p-3 border-bottom bg-light">
                <button className="btn btn-link text-secondary">
                    <FaArrowLeft size={20} onClick={handleNavigateMachine} />
                </button>
                <div className="d-flex align-items-center gap-3">
                    <button className="btn custom-btn-outline text-warning p-2" onClick={handleNavigateMachine}>
                        Retired Machines
                    </button>
                    <button className="btn custom-btn-outline p-2" onClick={handleNavigateMachine}>
                        Machine List
                    </button>
                    <button className="btn custom-btn-outline p-2">
                        Submit Machine Review
                    </button>
                    <button className="btn custom-btn-outline text-success p-2">
                        <span className="me-2 rounded-circle bg-success d-inline-block" style={{ width: '8px', height: '8px' }}></span>
                        {machine.name} is online
                    </button>
                </div>
            </div>

            {/* Machine header */}
            <div className="p-4 border-bottom d-flex align-items-center gap-4">
                <div className="position-relative">
                    <div
                        className="rounded-circle d-flex align-items-center justify-content-center"
                        style={{
                            width: '96px',
                            height: '96px',
                            background: 'linear-gradient(to bottom right, #4dd4c6, #2563eb)',
                            fontSize: '70px'
                        }}
                    >
                        {machine.icon}
                    </div>
                </div>

                <div className="flex-grow-1">
                    <div className="d-flex align-items-center gap-3">
                        <h1 className="h3 fw-bold">{machine.name}</h1>
                        <div className="text-secondary d-flex align-items-center gap-2">
                            <span>Linux</span>
                            <span>•</span>
                            <span>Easy</span>
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center gap-5">
                    <div className="text-center">
                        <div className="fs-3 fw-bold">0</div>
                        <div className="text-secondary small">Points</div>
                    </div>

                    <div className="text-center">
                        <div className="d-flex text-warning fs-4">
                            <FaStar /><FaStar /><FaStar /><FaStar />
                            <FaStar className="text-warning opacity-50" />
                        </div>
                        <div className="text-secondary small">{machine.rating}</div>
                    </div>

                    <div className="text-center">
                        <div style={{ height: '32px', width: '96px' }}>
                            <div className="rating-bar">
                                <div className="bar-item" style={{ height: '30%' }}></div>
                                <div className="bar-item" style={{ height: '60%' }}></div>
                                <div className="bar-item" style={{ height: '90%' }}></div>
                                <div className="bar-item" style={{ height: '70%' }}></div>
                                <div className="bar-item" style={{ height: '40%' }}></div>
                                <div className="bar-item" style={{ height: '20%' }}></div>
                                <div className="bar-item" style={{ height: '10%' }}></div>
                                <div className="bar-item" style={{ height: '5%' }}></div>
                            </div>
                        </div>
                        <div className="text-secondary small">User Rated Difficulty</div>
                    </div>
                </div>
            </div>

            {/* Tabs navigation */}
            <div className="border-bottom">
                <Tabs
                    value={activeTab}
                    onChange={(_, newValue) => setActiveTab(newValue)}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="navigation tabs"
                    className="mb-4"
                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: '#7f56d9',
                        },
                    }}
                >
                    {tabs.map((tab) => (
                        <Tab
                            key={tab.id}
                            value={tab.id}
                            label={
                                <div className="d-flex align-items-center gap-2">
                                    {tab.label}
                                    {tab.icon}
                                </div>
                            }
                            sx={{
                                '&.Mui-selected': { color: '#7f56d9', borderColor: '#7f56d9' },
                            }}

                            className="text-sm font-medium"
                        />
                    ))}
                </Tabs>

                <div className="tab-content">
                    {
                        activeTab === "activeMachine" && (<div className="tab-pane active p-4">
                            {/* Game mode selection */}
                            <div className="d-flex gap-3 mb-4">
                                <button
                                    className={`btn rounded-pill ${selectedMode === "adventure" ? "btn-outline-primary" : "btn-outline-secondary"}`}
                                    onClick={() => setSelectedMode("adventure")}
                                >
                                    <div className="d-flex align-items-center gap-2">
                                        <div
                                            className="mode-radio"
                                            style={{ borderColor: selectedMode === "adventure" ? "#7F56D9" : "#6c757d" }}
                                        >
                                            {selectedMode === "adventure" && <div className="mode-radio-inner"></div>}
                                        </div>
                                        Adventure Mode
                                    </div>
                                </button>
                                <button
                                    className={`btn rounded-pill ${selectedMode === "guided" ? "btn-outline-primary" : "btn-outline-secondary"}`}
                                    onClick={() => setSelectedMode("guided")}
                                >
                                    <div className="d-flex align-items-center gap-2">
                                        <div
                                            className="mode-radio"
                                            style={{ borderColor: selectedMode === "guided" ? "#7F56D9" : "#6c757d" }}
                                        >
                                            {selectedMode === "guided" && <div className="mode-radio-inner"></div>}
                                        </div>
                                        Guided Mode
                                    </div>
                                </button>
                            </div>

                            {/* Region and player info */}
                            <div className="d-flex justify-content-between mb-0 small">
                                <div className="d-flex align-items-center gap-2">
                                    <span className="rounded-circle bg-success" style={{ width: '8px', height: '8px' }}></span>
                                    <span className="text-success">EU VIP+ 1</span>
                                </div>
                                <div className="d-flex align-items-center gap-2 text-secondary">
                                    <FaUser size={16} />
                                    <span>1 player</span>
                                </div>
                            </div>

                            {/* Target IP Address */}
                            <div className="mb-2">
                                <div className="text-secondary mb-0">Target IP Address</div>
                                <div className="mt-0 pt-0 d-flex justify-content-between align-items-center">
                                    <div className="fs-5 font-monospace text-success">{machine.ip}</div>
                                    <div className="d-flex gap-2">
                                        <button className="custom-btn-outline">
                                            <FaStopCircle size={14} className="text-danger" />
                                        </button>
                                        <button className="custom-btn-outline">
                                            <FaSyncAlt size={14} className="text-secondary" />
                                        </button>
                                        <button className="custom-btn-outline">
                                            <FaClock className="me-2 text-secondary" size={16} />
                                            23:57:49
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/* Task section */}
                            <div className="bg-light rounded p-4 mb-4 border">
                                <div className="d-flex align-items-center gap-3 mb-3">
                                    <div className="d-flex gap-2 align-items-center">
                                        <div
                                            className="rounded-circle border border-success d-flex align-items-center justify-content-center"
                                            style={{ width: '24px', height: '24px', borderWidth: '2px' }}
                                        >
                                            <div className="rounded-circle bg-success" style={{ width: '8px', height: '8px' }}></div>
                                        </div>
                                        <div className="fw-medium" style={{ fontSize: '14px' }}>Task 1</div>
                                    </div>
                                    <div className="ms-auto">
                                        <button className="custom-btn-outline">
                                            <FaLightbulb size={14} className="me-2" style={{ color: '#7F56D9' }} />
                                            Hint
                                        </button>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <h3 className="mb-3" style={{ fontWeight: '400', fontSize: '14px' }}>How many TCP ports are open on the remote host?</h3>
                                    <input
                                        type="text"
                                        placeholder="number, such as 3, 17, or 4567"
                                        className="form-control"
                                        value={answer}
                                        onChange={(e) => setAnswer(e.target.value)}
                                    />
                                </div>

                                <div className="d-flex justify-content-end">
                                    <button
                                        className="btn"
                                        style={{ backgroundColor: '#7F56D9', color: 'white' }}
                                        disabled={!answer}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>)
                    }
                </div>
            </div>
        </div>
    )
}