import React from "react";
import "../Footer/Footer.css";

import "../../assets/js/core/popper.min.js";
import "../../assets/js/core/bbl-ui.js";
import "../../assets/js/plugins/perfect-scrollbar.min.js";
import "../../assets/js/plugins/smooth-scrollbar.min.js";
import "../../assets/js/plugins/dropzone.min.js";
import "../../assets/js/plugins/dragula/dragula.min.js";
import "../../assets/js/plugins/jkanban/jkanban.js";
import "../../assets/js/bbl.js"
import { useLocation } from "react-router-dom";
import ChatBox from "../../../FrontEnd/components/ChatBox.jsx";

const renderChatBot = (pathname) => {
	if (pathname.includes('login') || pathname.includes('logout'))
		return null
	if (pathname.includes('researcher'))
		return <ChatBox type="RESEARCHER" />
	else if (pathname.includes('customer'))
		return <ChatBox type="CUSTOMER" />
	else
		return null
}

const Footer = () => {
	const location = useLocation();
	return (
		<>
			{localStorage.getItem("showLockScreen") != 'true' && renderChatBot(location?.pathname)}
			<footer className="footer-div py-4 pb-2">
				<div className="container">
					<div className="text-center">
						<div className="text-center text-sm text-muted">
							{/* {new Date().getFullYear()} */}
							<p>©  {new Date().getFullYear()}. <strong>BugBustersLabs Private Limited</strong> .All Rights Reserved</p>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
