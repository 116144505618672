import { Tab, Tabs } from "@mui/material"; // Keeping Material-UI Tabs as Bootstrap doesn't have an exact equivalent
import { useState } from "react";
import {
    FiAward as Award,
    FiChevronRight as ChevronRight,
    FiInfo as Info,
    FiGrid as LayoutGrid,
    FiList as List,
    FiSearch as Search,
    FiServer as Server,
    FiShoppingCart as ShoppingCart,
    FiStar as Star,
    FiUser as User
} from "react-icons/fi";
import { TfiCrown as Crown } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import "./machine.css";
export const challenges = [
    {
        id: 1,
        name: "TwoMillion",
        difficulty: "EASY",
        difficultyColor: "text-success",
        difficultyBars: [
            { level: 1, active: true, color: "bg-success" },
            { level: 2, active: true, color: "bg-success" },
            { level: 3, active: true, color: "bg-success" },
            { level: 4, active: false, color: "bg-warning" },
            { level: 5, active: false, color: "bg-warning" },
            { level: 6, active: false, color: "bg-danger" },
            { level: 7, active: false, color: "bg-danger" },
            { level: 8, active: false, color: "bg-danger" },
            { level: 9, active: false, color: "bg-danger" },
            { level: 10, active: false, color: "bg-danger" },
        ],
        rating: 4.7,
        userOwns: 2461,
        systemOwns: 2254,
        isPremium: false,
        icon: "🌍",
        iconBg: "bg-purple-100",
        type: "active",
        status: "Available",
        ip: "13.82.104.236",
    },
    {
        ip: "137.135.68.164",
        id: 4,
        name: "ZeroDay",
        difficulty: "HARD",
        difficultyColor: "text-danger",
        difficultyBars: [
            { level: 1, active: true, color: "bg-success" },
            { level: 2, active: true, color: "bg-success" },
            { level: 3, active: true, color: "bg-warning" },
            { level: 4, active: true, color: "bg-warning" },
            { level: 5, active: true, color: "bg-warning" },
            { level: 6, active: true, color: "bg-danger" },
            { level: 7, active: true, color: "bg-danger" },
            { level: 8, active: true, color: "bg-danger" },
            { level: 9, active: false, color: "bg-danger" },
            { level: 10, active: false, color: "bg-danger" },
        ],
        rating: 4.8,
        userOwns: 2789,
        systemOwns: 2543,
        isPremium: false,
        icon: "🐞",
        iconBg: "bg-yellow-100",
        type: "active",
        status: "Available"
    },

    {
        ip: "13.82.104.10",
        id: 5,
        name: "Phantom",
        difficulty: "MEDIUM",
        difficultyColor: "text-warning",
        difficultyBars: [
            { level: 1, active: true, color: "bg-success" },
            { level: 2, active: true, color: "bg-success" },
            { level: 3, active: true, color: "bg-warning" },
            { level: 4, active: true, color: "bg-warning" },
            { level: 5, active: true, color: "bg-warning" },
            { level: 6, active: false, color: "bg-danger" },
            { level: 7, active: false, color: "bg-danger" },
            { level: 8, active: false, color: "bg-danger" },
            { level: 9, active: false, color: "bg-danger" },
            { level: 10, active: false, color: "bg-danger" },
        ],
        rating: 4.5,
        userOwns: 2345,
        systemOwns: 2101,
        isPremium: false,
        icon: "👻",
        iconBg: "bg-blue-100",
        type: "active",
        status: "Unavailable"
    },
    {
        id: 2,
        name: "CyberWarrior",
        difficulty: "MEDIUM",
        difficultyColor: "text-warning",
        difficultyBars: [
            { level: 1, active: true, color: "bg-success" },
            { level: 2, active: true, color: "bg-success" },
            { level: 3, active: true, color: "bg-warning" },
            { level: 4, active: true, color: "bg-warning" },
            { level: 5, active: false, color: "bg-warning" },
            { level: 6, active: false, color: "bg-danger" },
            { level: 7, active: false, color: "bg-danger" },
            { level: 8, active: false, color: "bg-danger" },
            { level: 9, active: false, color: "bg-danger" },
            { level: 10, active: false, color: "bg-danger" },
        ],
        rating: 4.3,
        userOwns: 1987,
        systemOwns: 1765,
        isPremium: true,
        icon: "🛡️",
        iconBg: "bg-indigo-100",
        type: "retired",
        status: "Unavailable",
        ip: "13.82.104.236",
    },
    {
        ip: "13.82.104.236",
        id: 3,
        name: "HackThePlanet",
        difficulty: "HARD",
        difficultyColor: "text-danger",
        difficultyBars: [
            { level: 1, active: true, color: "bg-success" },
            { level: 2, active: true, color: "bg-success" },
            { level: 3, active: true, color: "bg-warning" },
            { level: 4, active: true, color: "bg-warning" },
            { level: 5, active: true, color: "bg-warning" },
            { level: 6, active: true, color: "bg-danger" },
            { level: 7, active: true, color: "bg-danger" },
            { level: 8, active: false, color: "bg-danger" },
            { level: 9, active: false, color: "bg-danger" },
            { level: 10, active: false, color: "bg-danger" },
        ],
        rating: 4.9,
        userOwns: 3124,
        systemOwns: 2890,
        isPremium: true,
        icon: "💻",
        iconBg: "bg-red-100",
        type: "retired",
        status: "Unavailable"
    },

    {
        ip: "13.82.104.236",
        id: 6,
        name: "StealthOps",
        difficulty: "EASY",
        difficultyColor: "text-success",
        difficultyBars: [
            { level: 1, active: true, color: "bg-success" },
            { level: 2, active: true, color: "bg-success" },
            { level: 3, active: true, color: "bg-success" },
            { level: 4, active: false, color: "bg-warning" },
            { level: 5, active: false, color: "bg-warning" },
            { level: 6, active: false, color: "bg-danger" },
            { level: 7, active: false, color: "bg-danger" },
            { level: 8, active: false, color: "bg-danger" },
            { level: 9, active: false, color: "bg-danger" },
            { level: 10, active: false, color: "bg-danger" },
        ],
        rating: 4.6,
        userOwns: 2200,
        systemOwns: 2000,
        isPremium: false,
        icon: "🕵️",
        iconBg: "bg-green-100",
        type: "deactive",
        status: "Unavailable"
    },
    {
        ip: "13.82.104.236",
        id: 7,
        name: "RedAlert",
        difficulty: "HARD",
        difficultyColor: "text-danger",
        difficultyBars: [
            { level: 1, active: true, color: "bg-success" },
            { level: 2, active: true, color: "bg-success" },
            { level: 3, active: true, color: "bg-warning" },
            { level: 4, active: true, color: "bg-warning" },
            { level: 5, active: true, color: "bg-warning" },
            { level: 6, active: true, color: "bg-danger" },
            { level: 7, active: true, color: "bg-danger" },
            { level: 8, active: true, color: "bg-danger" },
            { level: 9, active: true, color: "bg-danger" },
            { level: 10, active: true, color: "bg-danger" },
        ],
        rating: 4.9,
        userOwns: 3000,
        systemOwns: 2800,
        isPremium: true,
        icon: "🚨",
        iconBg: "bg-red-100",
        type: "kali",
        status: "Unavailable"
    }
];
export function CyberDashboard({ roleType }) {
    const [activeTab, setActiveTab] = useState("active");
    const [viewMode, setViewMode] = useState("list");
    const navigate = useNavigate();
    const tabs = [
        { id: "active", label: "ACTIVE MACHINES" },
        { id: "retired", label: "RETIRED MACHINES" },
        { id: "deactive", label: "Deactivated Machines" },
        { id: "kali", label: "Browser UI Kali Linux" },

    ];

    const handleMachineClick = (machine) => {
        navigate(`/${roleType}/machines/${machine.name}`);
    }

    return (
        <div className="machines">
            <div className="min-vh-100 bg-white text-dark font-poppins">
                <div className="container-fluid px-4 py-5">
                    {/* Header Section */}
                    <div className="row justify-content-between gap-3 mb-5">
                        {/* Featured Challenges */}
                        <div className="col-lg-6">
                            <div className="row g-3">
                                <div className="col-md-5 d-flex align-items-center gap-3">
                                    <div className="position-relative">
                                        <div className="rounded-circle bg-gradient-purple d-flex align-items-center justify-content-center border border-2 border-primary shadow" style={{ width: '80px', height: '80px' }}>
                                            <ShoppingCart className="text-primary" style={{ width: '40px', height: '40px' }} />
                                        </div>
                                        <div style={{ right: '-10px !important' }} className="position-absolute bottom-0 end-0 rounded-circle bg-white d-flex align-items-center justify-content-center border">
                                            <span className="small text-muted fw-medium badgeRounded">W</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span className="badge bg-purple-100 text-primary mb-1">ResearchLab</span>
                                        <h3 className="h5 fw-semibold text-dark mb-0">Lab Machines</h3>
                                        <div className="text-danger small fw-medium">HARD</div>
                                    </div>
                                </div>
                                <div className="col-md-5 d-flex align-items-center gap-3">
                                    <div className="position-relative">
                                        <div className="rounded-circle bg-gradient-indigo d-flex align-items-center justify-content-center border border-2 border-primary shadow" style={{ width: '80px', height: '80px' }}>
                                            <Server className="text-primary" style={{ width: '40px', height: '40px' }} />
                                        </div>
                                        <div style={{ right: '-10px !important' }} className="position-absolute bottom-0 end-0 rounded-circle bg-white d-flex align-items-center justify-content-center border">
                                            <span className="small text-muted fw-medium badgeRounded">W</span>
                                        </div>
                                    </div>
                                    <div>
                                        <span className="badge bg-indigo-100 text-primary mb-1">Cybersecurity</span>
                                        <h3 className="h5 fw-semibold text-dark mb-0">CTF Machines</h3>
                                        <div className="text-warning small fw-medium">MEDIUM</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Promotions */}
                        <div className="col-lg-3 d-flex flex-column gap-3">
                            <div className="d-flex align-items-center gap-3 bg-light-1 p-3 rounded border hover-border-primary">
                                <div className="bg-white p-2 rounded shadow-sm">
                                    <Award className="text-primary" style={{ width: '20px', height: '20px' }} />
                                </div>
                                <div>
                                    <div className="text-primary small fw-medium viptitle">GET REWARDED</div>
                                    <div className="text-dark fw-semibold vipSub">Submit a Machine</div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center gap-3 bg-light-1 p-3 rounded border hover-border-primary">
                                <div className="bg-white p-2 rounded shadow-sm">
                                    <Crown className="text-primary" style={{ width: '20px', height: '20px' }} />
                                </div>
                                <div>
                                    <div className="text-primary small fw-medium viptitle">24H/MO PWNBOX + RETIRED CONTENT</div>
                                    <div className="text-dark fw-semibold vipSub">Upgrade to VIP</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Material-UI Tabs (kept as is) */}
                    <Tabs
                        value={activeTab}
                        onChange={(_, newValue) => setActiveTab(newValue)}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="navigation tabs"
                        className="mb-4"
                        sx={{
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#7f56d9',
                            },
                        }}
                    >
                        {tabs.map((tab) => (
                            <Tab
                                key={tab.id}
                                value={tab.id}
                                label={
                                    <div className="d-flex align-items-center gap-2">
                                        {tab.label}
                                        <Info style={{ fontSize: '16px', color: activeTab === tab.id ? '#7f56d9' : '' }} />
                                    </div>
                                }
                                sx={{
                                    '&.Mui-selected': { color: '#7f56d9', borderColor: '#7f56d9' },
                                }}

                                className="text-sm font-medium"
                            />
                        ))}
                    </Tabs>

                    {/* Search and Filters */}
                    <div className="mb-4">
                        <div className="position-relative mb-3">
                            <Search className="position-absolute top-50 translate-middle-y ms-3 text-muted" style={{ width: '16px', height: '16px' }} />
                            <input
                                type="text"
                                placeholder="Search retired machines..."
                                className="form-control ps-5"
                            />
                        </div>
                        {/* <div className="d-flex flex-wrap gap-3 align-items-center">
                            <div className="d-flex align-items-center gap-2">
                                <span className="small text-muted fw-medium">STATUS:</span>
                                <button className="btn btn-primary btn-primary-1 btn-sm fw-medium">BOTH</button>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="small text-muted fw-medium">SORT BY</span>
                                <button className="btn btn-outline-secondary btn-sm fw-medium d-flex align-items-center gap-1">
                                    <span>RELEASE DATE</span>
                                    <ChevronDown style={{ width: '12px', height: '12px' }} />
                                </button>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="small text-muted fw-medium">DIFFICULTY</span>
                                <button className="btn btn-outline-secondary btn-sm fw-medium d-flex align-items-center gap-1">
                                    <span>ALL</span>
                                    <ChevronDown style={{ width: '12px', height: '12px' }} />
                                </button>
                            </div>
                            <div className="d-flex align-items-center gap-2">
                                <span className="small text-muted fw-medium">OS</span>
                                <button className="btn btn-outline-secondary btn-sm fw-medium d-flex align-items-center gap-1">
                                    <span>ALL</span>
                                    <ChevronDown style={{ width: '12px', height: '12px' }} />
                                </button>
                            </div>
                            <button className="btn btn-link text-primary small fw-medium ms-auto p-0">
                                ADVANCED SEARCH
                            </button>
                        </div> */}
                    </div>

                    {/* View Mode Toggle */}
                    <div className="mt-4">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <div className="small fw-bold text-muted">MACHINE</div>
                            <div className="btn-group">
                                <button
                                    className={`${viewMode === "list" ? "menu-active-btn" : "menu-btn"}`}
                                    onClick={() => setViewMode("list")}
                                >
                                    <List style={{ width: '20px', height: '20px' }} />
                                </button>
                                <button
                                    className={`${viewMode === "grid" ? "menu-active-btn" : "menu-btn"}`}
                                    onClick={() => setViewMode("grid")}
                                >
                                    <LayoutGrid style={{ width: '20px', height: '20px' }} />
                                </button>
                            </div>
                        </div>

                        {/* Challenge List */}
                        {viewMode === "list" ? (
                            <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr className="text-muted small fw-medium">
                                            <th className="text-start" scope="col" style={{ width: '25%' }}>MACHINE</th>
                                            <th className="text-start" scope="col" style={{ width: '15%' }}>MACHINES STATUS</th>
                                            <th className="text-start" scope="col" style={{ width: '15%' }}>ESSENTIAL TOOL KIT</th>
                                            <th className="text-start" scope="col" style={{ width: '10%' }}>MACHINE REVIEW RATINGS</th>
                                            <th className="text-start" scope="col" style={{ width: '10%' }}>USER OWNS</th>
                                            <th className="text-start" scope="col" style={{ width: '10%' }}>SYSTEM OWNS</th>
                                            <th className="text-start" scope="col" style={{ width: '15%' }}></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {challenges.filter(el => el.type === activeTab).map((challenge) => (
                                            <tr key={challenge.id} onClick={() => handleMachineClick(challenge)} >
                                                <td className="text-start align-middle">
                                                    <div className="d-flex align-items-center gap-3">
                                                        <div className={`rounded-circle ${challenge.iconBg} d-flex align-items-center justify-content-center shadow-sm border border-purple-200`} style={{ width: '48px', height: '48px' }}>
                                                            <span className="fs-4">{challenge.icon}</span>
                                                        </div>
                                                        <div>
                                                            <div className="fw-semibold text-dark">{challenge.name}</div>
                                                            <div className={`small fw-medium ${challenge.difficultyColor}`}>{challenge.difficulty}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="align-middle text-start ">
                                                    <span className={`badge ${challenge.status !== "Unavailable" ? "bg-success text-white" : "bg-warning text-dark"}`}>
                                                        {challenge.status}
                                                    </span>
                                                </td>
                                                <td className="text-start align-middle">
                                                    <div className="d-flex align-items-center gap-1 justify-content-start ">
                                                        {challenge.difficultyBars.map((bar, index) => (
                                                            <div
                                                                key={index}
                                                                className={`rounded ${bar.active ? bar.color : "bg-light"}`}
                                                                style={{ width: '10px', height: '20px' }}
                                                            ></div>
                                                        ))}
                                                    </div>
                                                </td>
                                                <td className="text-start align-middle">
                                                    <div className="d-flex align-items-center justify-content-start gap-1">
                                                        <Star className="text-warning fill-warning" style={{ width: '16px', height: '16px' }} />
                                                        <span>{challenge.rating}</span>
                                                    </div>
                                                </td>
                                                <td className="text-start align-middle">
                                                    <div className="d-flex align-items-center justify-content-start gap-1">
                                                        <User className="text-primary" style={{ width: '16px', height: '16px' }} />
                                                        <span>{challenge.userOwns}</span>
                                                    </div>
                                                </td>
                                                <td className="text-start align-middle">
                                                    <div className="d-flex align-items-center justify-content-start gap-1">
                                                        <Server className="text-primary" style={{ width: '16px', height: '16px' }} />
                                                        <span>{challenge.systemOwns}</span>
                                                    </div>
                                                </td>
                                                <td className="text-start align-middle">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <span className="text-primary fw-semibold small">FREE</span>
                                                        <ChevronRight className="text-muted" style={{ width: '20px', height: '20px' }} />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 g-4">
                                {challenges.filter(el => el.type === activeTab).map((challenge) => (
                                    <div key={challenge.id} className="col">
                                        <div className="card h-100 border hover-border-primary">
                                            <div className="card-body position-relative ">
                                                <span className={`badge position-absolute top-0 end-0 mt-2 me-2 ${challenge.status === 'Available' ? "bg-success text-white" : "bg-warning text-dark"}`}>
                                                    {challenge.status}
                                                </span>
                                                <div className="d-flex align-items-center gap-3 mb-3">
                                                    <div className={`rounded-circle ${challenge.iconBg} d-flex align-items-center justify-content-center shadow-sm border border-purple-200`} style={{ width: '48px', height: '48px' }}>
                                                        <span className="fs-4">{challenge.icon}</span>
                                                    </div>
                                                    <div>
                                                        <div className="fw-semibold text-dark">{challenge.name}</div>
                                                        <div className={`small fw-medium ${challenge.difficultyColor}`}>{challenge.difficulty}</div>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center gap-1 mb-3">
                                                    {challenge.difficultyBars.map((bar, index) => (
                                                        <div
                                                            key={index}
                                                            className={`rounded ${bar.active ? bar.color : "bg-light"}`}
                                                            style={{ width: '10px', height: '20px' }}
                                                        ></div>
                                                    ))}
                                                </div>
                                                <div className="row text-center">
                                                    <div className="col-4">
                                                        <div className="d-flex justify-content-center align-items-center gap-1">
                                                            <Star className="text-warning fill-warning" style={{ width: '16px', height: '16px' }} />
                                                            <span>{challenge.rating}</span>
                                                        </div>
                                                        <div className="text-muted small">Rating</div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="d-flex justify-content-center align-items-center gap-1">
                                                            <User className="text-primary" style={{ width: '16px', height: '16px' }} />
                                                            <span>{challenge.userOwns}</span>
                                                        </div>
                                                        <div className="text-muted small">User</div>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="d-flex justify-content-center align-items-center gap-1">
                                                            <Server className="text-primary" style={{ width: '16px', height: '16px' }} />
                                                            <span>{challenge.systemOwns}</span>
                                                        </div>
                                                        <div className="text-muted small">System</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pt-0 card-footer d-flex justify-content-between align-items-center bg-white">
                                                <span className="text-primary fw-semibold small">FREE</span>
                                                <button onClick={() => handleMachineClick(challenge)} className="btn btn-primary btn-sm btn-primary-1">Details</button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}