import React from "react";
import { Route, Routes } from "react-router-dom";
import ResearchersManagementDashboard from "../BackEnd/Researcher-part/Researcher-Management/ResearcherManagementDashboard";
import ResearcherKyc from "../BackEnd/Researcher-part/Researcher-Payments/ResearcherKyc";
import ResearcherTransaction from "../BackEnd/Researcher-part/Researcher-Payments/ResearcherTransaction";
import ResearcherWallet from "../BackEnd/Researcher-part/Researcher-Payments/ResearcherWallet";
import ResearcherProgramDashbord from "../BackEnd/Researcher-part/Researcher-Program/Researcher-Program-Dashboard/ResearcherProgramDashboard";
import ResearcherReportsDashboard from "../BackEnd/Researcher-part/Researcher-Reports/ResearcherReportsDashboard";
import ResearcherSubmission from "../BackEnd/Researcher-part/Researcher-Submission/ResearcherSubmission";
import ResearcherSubmissonEdit from "../BackEnd/Researcher-part/Researcher-Submission/ResearcherSubmissionEdit";
import ResearcherSubmissionNew from "../BackEnd/Researcher-part/Researcher-Submission/ResearcherSubmissionNew";
import ResearcherAdd from "../BackEnd/Researcher-part/Researcher-home/ResearcherAdd";
import ResearcherEdit from "../BackEnd/Researcher-part/Researcher-home/ResearcherEdit";
import ResearcherHome from "../BackEnd/Researcher-part/Researcher-home/ResearcherHome";
import ResearcherView from "../BackEnd/Researcher-part/Researcher-home/ResearcherView";
import ResearcherMainProgramTab from "../BackEnd/Researcher-part/ResearcherProgramTab/ResearcherMainProgramTab";
import ResearcherProgramAnnouncement from "../BackEnd/Researcher-part/ResearcherProgramTab/ResearcherProgramAnnouncement";
import ResearcherProgramResources from "../BackEnd/Researcher-part/ResearcherProgramTab/ResearcherProgramResources";
import ResearcherProgramReward from "../BackEnd/Researcher-part/ResearcherProgramTab/ResearcherProgramReward";
import ResearcherProgramSubmission from "../BackEnd/Researcher-part/ResearcherProgramTab/ResearcherProgramSubmission";
import ResearcherProgramSummary from "../BackEnd/Researcher-part/ResearcherProgramTab/ResearcherProgramSummary";
import ResearcherStatsReports from "../BackEnd/Researcher-part/Researcher_stats/ResearchStatsReports";
import VTxTaxonomy from "../BackEnd/Researcher-part/master-module/VTxTaxonomy/VTxTaxonomy";
import ViewVTxTaxonomy from "../BackEnd/Researcher-part/master-module/VTxTaxonomy/ViewVTxTaxonomy";
import ResearcherBasic from "../BackEnd/Researcher-part/signin/ResearcherBasic";
import { CyberDashboard } from "../BackEnd/common/Active-Machines/ActiveMachines";
import MachinesDashboard from "../BackEnd/common/Active-Machines/Machine/Machine";
import Addskills from "../BackEnd/common/Addskills";
import Basicinfo from "../BackEnd/common/Basicinfo";
import KycProfile from "../BackEnd/common/KycProfile";
import Paymentinfo from "../BackEnd/common/Paymentinfo";
import ProgramTracking from "../BackEnd/common/ProgramTracking";
import ProgramReport from "../BackEnd/common/ReportsTypes/ProgramReport";
import ResearcherProgramReport from "../BackEnd/common/ReportsTypes/ResearcherProgramReport";
import ResearcherReport from "../BackEnd/common/ReportsTypes/ResearcherReport";
import SubmisisonReport from "../BackEnd/common/ReportsTypes/SubmissionReport";
import Social from "../BackEnd/common/Social";
import Jira from "../BackEnd/common/integrations/Jira/JIra";
import Slack from "../BackEnd/common/integrations/Slack/Slack";
import Footer from "../BackEnd/components/Footer/Footer";
import Dashboard from "../BackEnd/components/Header-Components/Dashboard";
import MachineHeader from "../BackEnd/components/Header-Components/MachineHeader";
import Management from "../BackEnd/components/Header-Components/Management";
import Payments from "../BackEnd/components/Header-Components/Payments";
import Reports from "../BackEnd/components/Header-Components/Reports";
import Header from "../BackEnd/components/Header/Header";
import Notifications from "../BackEnd/components/Notifications";
import ViewSubmission from "../BackEnd/dashboard/submission/ViewSubmission";
import Resetpassword from "../BackEnd/resetpassword/Resetpassword";
const ResearcherLayout = (props) => {
  return (
    <>
      <Header returnhome="/researcher/home">
        <Dashboard Dashboardlink="/researcher/home" />
        <Management usertype="researcher" />
        <Payments usertype="researcher" />
        {/* <Integrations usertype="researcher" /> */}
        <Reports usertype="researcher" />
        {/* <MasterModule usertype="researcher" /> */}
        <MachineHeader route="/researcher/cyber-dashboard" />

      </Header>
      <div className="bg-gray-100">
        <main className="position-relative mt-0" style={{ height: "auto" }}>
          <Routes>
            <>
              <Route path="/cyber-dashboard" element={<CyberDashboard roleType="researcher" />} />
              <Route path="/machines/:name" element={<MachinesDashboard roleType="researcher" />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/" element={<ResearcherBasic />} />
              <Route path="/home" element={<ResearcherHome />} />
              <Route path="/resetpassword" element={<Resetpassword />} />
              <Route
                path="/management/dashboard"
                element={<ResearchersManagementDashboard />}
              />
              {/* <Route
								path="/lockscreen"
								element={<Userlockscreen />}
							/> */}
              <Route path="/submissions" element={<ResearcherSubmission />} />
              <Route
                path="/submissions/new"
                element={<ResearcherSubmissionNew />}
              />
              <Route
                path="/submissions/new/:programId"
                element={<ResearcherSubmissionNew />}
              />
              <Route
                path="/submissions/edit/:id"
                element={<ResearcherSubmissonEdit />}
              />
              <Route
                path="/submissions/view/:id"
                element={<ViewSubmission />}
              />
              <Route
                path="/researcher/view/:userId"
                element={<ResearcherView />}
              />
              <Route
                path="/user/view/:userId/:type"
                element={<ResearcherView />}
              />
              <Route
                exact
                path="/user/edit/:userId/:type"
                element={<ResearcherEdit />}
              >
                <Route path="basicinfo" element={<Basicinfo />} />
                <Route path="addskill" element={<Addskills />} />
                <Route path="paymentinformation" element={<Paymentinfo />} />
                <Route path="social" element={<Social />} />
                <Route path="kyc" element={<KycProfile />} />
              </Route>
              <Route path="/user/add" element={<ResearcherAdd />} />
              <Route
                path="/program/dashboard"
                element={<ResearcherProgramDashbord />}
              />

              <Route
                path="/programs/:id/"
                element={<ResearcherMainProgramTab />}
              >
                <Route path="summary" element={<ResearcherProgramSummary />} />
                <Route
                  path="submission"
                  element={<ResearcherProgramSubmission />}
                />
                <Route path="rewards" element={<ResearcherProgramReward />} />
                <Route
                  path="announcement"
                  element={<ResearcherProgramAnnouncement />}
                />
                <Route
                  path="resource"
                  element={<ResearcherProgramResources />}
                />
                <Route
                  path="tracking"
                  element={<ProgramTracking />}
                />
              </Route>

              <Route
                path="/programs/reports"
                element={<ResearcherStatsReports />}
              />
              <Route path="/payments/wallet" element={<ResearcherWallet />} />
              <Route
                path="/payments/transaction"
                element={<ResearcherTransaction />}
              />
              <Route
                path="/reports-dashboard"
                element={<ResearcherReportsDashboard />}
              />
              <Route path="/reports-dashboard/program/:id" element={<ProgramReport />} />
              <Route path="/reports-dashboard/researcher/:id" element={<ResearcherReport />} />
              <Route path="/reports-dashboard/submission/:id" element={<SubmisisonReport />} />
              <Route path="/reports-dashboard/researcher-program/:researcherId/:programId" element={<ResearcherProgramReport />} />
              <Route path="/kyc" element={<ResearcherKyc />} />
              <Route path="/vtxtaxonomy/dashboard" element={<VTxTaxonomy />} />
              <Route path="/vtxtaxonomy/view/:id" element={<ViewVTxTaxonomy />} />
              <Route path="/integrations/slack" element={<Slack />} />
              <Route path="/integrations/jira" element={<Jira />} />
            </>
          </Routes>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default ResearcherLayout;
