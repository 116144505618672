import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./css/frontFooter.css";
import "./js/webflow.js";

import logoWhite from "./images/logoWhite.png";
import ChatBox from "./ChatBox.jsx";
import HTTPService from "../../utils/axios.js";
import useHttp from "../../BackEnd/Hooks/useHttp.js";
const API = process.env.REACT_APP_API_URL;

export default function Footer() {
  const { invokeApi } = HTTPService();
  const { GetRequest, PutRequest, DeleteRequest } = useHttp();
  const [companyLink, setCompanyLink] = useState();
  const [cmsPage, setCmsPage] = useState();
  const [companyAddressData, setCompanyAddressdata] = useState();
  const [companyData, setCompanyData] = useState();
  const [navigationData, setNavigationData] = useState();
  const navigate = useNavigate();
  const fetchCompanyLink = async (stsId) => {
    invokeApi({
      method: "GET",
      url: "company_link",
      noToken: true
    })
      .then((data) => {
        setCompanyLink(data);
      })
      .catch((error) => {
        console.log("somethings went wrong");
      });
  };

  const fetchCompany = async () => {
    let url = `${API}company/`;
    const { data, error } = await GetRequest(url);
    if (error) {
      console.log("Error: while fetching company data");
    } else {
      setCompanyData(data);
      // setTotalPages(data.total_pages);
      //let cData=data.results
      //setCompanydata({...companydata,cData});
    }
  };

  const fetchCmsPage = async () => {
    const { data, error } = await GetRequest(API + "cms_pages/");
    if (error) {
      console.log("Error: fetching cms Data");
    } else {
      setCmsPage(data);
    }
  };

  const fetchCompanyAddress = async (stsId) => {
    invokeApi({
      method: "GET",
      url: "company_address",
      noToken: true
    })
      .then((data) => {
        setCompanyAddressdata(data.results);
      })
      .catch((error) => {
        console.log("somethings went wrong");
      });
  };

  const fetchNavigationData = async () => {
    let url = `${API}cms_navigation/`;
    const { data } = await GetRequest(url);
    if (data) {
      setNavigationData(data);
      fetchCmsPage();
    } else {
      console.log("Error: While fetching cms navigation");
    }
  };

  useEffect(() => {
    fetchCompany();
    fetchNavigationData();
    fetchCompanyLink();
    fetchCompanyAddress();
  }, []);

  return (
    <>
      <ChatBox />
      <div className="uui_footer">
        <div className="uui-footer07_container container">
          <div className="row d-flex">
            <div className="col-lg-2 col-6 sm-p-3">
              <h4>Information</h4>
              <ul className="text-white list-unstyled">
                <li className="pb-2">
                  <Link to="/aboutus">About Us</Link>
                </li>
                {navigationData?.results?.sort((a, b) => a.sequence - b.sequence)
                  ?.filter((data) => data.show_on === "Information")
                  .map((item, index) => (
                    <React.Fragment key={index}>
                      {cmsPage &&
                        cmsPage.results?.map(
                          (cms) =>
                            item.page_id === cms.id && (
                              <React.Fragment key={cms.id}>
                                <li className="pb-2">
                                  <Link to={`/cms/${cms.page_title}`}>{cms.page_title}.</Link>
                                </li>
                              </React.Fragment>
                            )
                        )}
                    </React.Fragment>
                  ))}

                {/* <li className="pb-2">
                  <Link to="">Our Customers</Link>
                </li>
                <li className="pb-2">
                  <Link to="">Careers</Link>
                </li>
                <li className="pb-2">
                  <Link to="">Partners</Link>
                </li>
                <li className="pb-2">
                  <Link to="">Press Releases</Link>
                </li>
                <li className="pb-2">
                  <Link to="">In the News</Link>
                </li> */}
                {/* <li>
                  <Link to="/contactus">Contact Us</Link>
                </li> */}
              </ul>
            </div>
            <div className="col-lg-2 col-6 sm-p-3">
              <h4>Business</h4>
              <ul className="text-white list-unstyled">
                {navigationData?.results?.sort((a, b) => a.sequence - b.sequence)
                  ?.filter((data) => data.show_on === "Business")
                  .map((item, index) => (
                    <React.Fragment key={index}>

                      {cmsPage &&
                        cmsPage.results?.map(
                          (cms) =>
                            item.page_id === cms.id && (
                              <React.Fragment key={cms.id}>
                                <li className="pb-2">
                                  <Link to={`/cms/${cms.page_title}`}>{cms.page_title}.</Link>
                                </li>
                              </React.Fragment>
                            )
                        )}
                    </React.Fragment>
                  ))}
                {/* <li className="pb-2">
                  <Link to="">Programs</Link>
                </li>
                <li className="pb-2">
                  <Link to="">Submissions</Link>
                </li>
                <li className="pb-2">
                  <Link to="">Bug Bounty List</Link>
                </li>
                <li className="pb-2">
                  <Link to="">Start Hacking</Link>
                </li>
                <li className="pb-2">
                  <Link to="/faq">FAQs</Link>
                </li>
                <li className="pb-2">
                  <Link to="">Hacker Docs</Link>
                </li>
                <li className="pb-2">
                  <Link to="">Bugbusterslabs University</Link>
                </li>
                <li>
                  <Link to="/Leaderboard">Leaderboard</Link>
                </li> */}
              </ul>
            </div>
            <div className="col-lg-2 col-6 sm-p-3">
              <h4>Resources</h4>
              <ul className="text-white list-unstyled">
                {navigationData?.results?.sort((a, b) => a.sequence - b.sequence)
                  ?.filter((data) => data.show_on === "Resources")
                  .map((item, index) => (
                    <React.Fragment key={index}>

                      {cmsPage &&
                        cmsPage.results?.map(
                          (cms) =>
                            item.page_id === cms.id && (
                              <React.Fragment key={cms.id}>

                                <li className="pb-2">
                                  <Link to={`/cms/${cms.page_title}`}>{cms.page_title}.</Link>
                                </li>
                              </React.Fragment>
                            )
                        )}
                    </React.Fragment>
                  ))}
                <li className="pb-2"
                  onClick={() => navigate("/resources/docs/customer")}
                >
                  <Link to="/resources/docs/customer">Customer Docs</Link>
                </li>
                <li className="pb-2"
                  onClick={() => navigate("/resources/docs/researcher")}
                >
                  <Link to="/resources/docs/researcher">
                    Researcher Docs
                  </Link>
                </li>
                <li className="pb-2"
                  onClick={() => navigate("/resources/docs/apis")}
                >
                  <Link to="/resources/docs/apis">API Docs</Link>
                </li>
                <li className="pb-2">
                  <a href="https://blog.bugbusterslabs.com/">Blog</a>
                </li>
                {/* <li className="pb-2">
                  <Link to="">Customer Resources</Link>
                </li>
                <li className="pb-2">
                  <Link to="">Researcher Resources</Link>
                </li>

                <li className="pb-2">
                  <Link to="">Events</Link>
                </li>
                <li className="pb-2">
                  <Link to="">Webinars</Link>
                </li>
                <li className="pb-2">
                  <Link to="">Glossary</Link>
                </li>
              
                <li>
                  <Link to="">Support</Link>
                </li> */}
              </ul>
            </div>
            <div className="col-lg-2 col-6 sm-p-3">
              <h4>Products</h4>
              <ul className="text-white list-unstyled">
                <li className="pb-2">
                  <Link to="/bug-bounty">Bug Bounty Platform</Link>
                </li>
                <li className="pb-2">
                  <Link to="/pentesting">Penetration Testing</Link>
                </li>
                <li className="pb-2">
                  <Link to="/externalattacksurface">
                    External Attack Surface
                  </Link>
                </li>
                <li className="pb-2">
                  <Link to="/redteaming">Red Teaming</Link>
                </li>
                <li className="pb-2">
                  <Link to="/darkwebmonitoring">Dark Web Monitoring</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-12 sm-p-3">
              <h4>Mailing Address</h4>
              <ul className="text-white list-unstyled">
                <li className="pb-2">
                  Email :
                  <Link to="mailto:support@bugbusterslabs.com">
                    {
                      companyData?.results?.filter(
                        (data) => data.allotment_type === "Main"
                      )[0].email
                    }
                  </Link>
                </li>
              </ul>
              <div className="addressone my-4">
                <span className="text-white">
                  <b>Legal Name:</b>
                </span>
                <h4
                  className="py-0 my-2"
                  style={{ fontSize: "16px", fontWeight: "500" }}
                >
                  {
                    companyData?.results?.filter(
                      (data) => data.allotment_type === "Main"
                    )[0].legal_name
                  }
                </h4>
              </div>
              <div className="addressone">
                <span className="text-white">
                  <b>Registered Office(India):</b>
                </span>
                <h4
                  className="py-0 my-2"
                  style={{ fontSize: "16px", fontWeight: "500" }}
                >
                  Bugbusterslabs Private Limited
                </h4>
                <p className="text-white">
                  {/* 1st Floor, 13, 3rd Cross Street, Kalaimagal Nagar,
                  Ekkattuthangal, Chennai - 600032, Tamilnadu, India. */}
                  {companyAddressData?.map(
                    (add) =>
                      add.company ==
                      companyData?.results?.filter(
                        (data) => data.allotment_type === "Main"
                      )[0].id &&
                      `${add.address1}, ${add.address2}, ${add.address3},\n${add.city}, ${add.state}, ${add.country}`
                  )}
                </p>
              </div>

              <div className="addresstwo mt-4">
                <span className="text-white" style={{ fontWeight: "500" }}>
                  Branch Office:
                </span>
                <h4
                  className="py-0 my-2"
                  style={{ fontSize: "16px", fontWeight: "500" }}
                >
                  Bugbusterslabs Private Limited
                </h4>
                <p className="text-white">
                  We Work Princeville, Domlur, Princeville, Embassy Golf Links
                  Business Park, off Intermediate ring road, Domlur, Bangalore -
                  560071, Karnataka, India.
                </p>
              </div>
              <div className="addresstwo mt-4">
                <span className="text-white">
                  <b>Registered Office (USA):</b>
                </span>

                <p className="text-white">
                  Bugbusterslabs Inc. 1111B S Governors Ave STE 20032 Dover, DE
                  19904.
                </p>
                <h4
                  className="py-0 my-2"
                  style={{ fontSize: "16px", fontWeight: "500" }}
                >
                  Bugbusterslabs,Inc
                </h4>
              </div>

              <div className="d-flex">
                <div className="socialIcn">
                  <Link
                    to={
                      companyLink?.results?.find(
                        (add) =>
                          add.company ===
                          companyData?.results?.find(
                            (data) => data.allotment_type === "Main"
                          )?.id && add.facebook
                      )?.facebook || "#"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-square-facebook"></i>
                  </Link>
                </div>
                <div className="socialIcn">
                  <Link
                    to={
                      companyLink?.results?.find(
                        (add) =>
                          add.company ===
                          companyData?.results?.find(
                            (data) => data.allotment_type === "Main"
                          )?.id && add.instagram
                      )?.instagram || "#"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fab fa-instagram-square"></i>
                  </Link>
                </div>
                <div className="socialIcn">
                  <Link
                    to={
                      companyLink?.results?.find(
                        (add) =>
                          add.company ===
                          companyData?.results?.find(
                            (data) => data.allotment_type === "Main"
                          )?.id && add.pinterest
                      )?.pinterest || "#"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-linkedin"></i>
                  </Link>
                </div>
                <div className="socialIcn">
                  <Link
                    to={
                      companyLink?.results?.find(
                        (add) =>
                          add.company ===
                          companyData?.results?.find(
                            (data) => data.allotment_type === "Main"
                          )?.id && add.youtube
                      )?.youtube || "#"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-youtube"></i>
                  </Link>
                </div>
                <div className="socialIcn">
                  <Link
                    to={
                      companyLink?.results?.find(
                        (add) =>
                          add.company ===
                          companyData?.results?.find(
                            (data) => data.allotment_type === "Main"
                          )?.id && add.twitter
                      )?.twitter || "#"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa-brands fa-square-x-twitter"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <div
              className=" d-flex align-items-center footerLogoBug"
              style={{ position: "absolute", height: "100%", width: "20%" }}
            >
              <img src={logoWhite} alt="" />
            </div>
            <div className="uui-text-size-small-3 text-color-gray500 text-center py-3 text-white ">
              © {new Date().getFullYear()} Bugbusterslabs. All rights reserved.
            </div>

            <div className="w-layout-grid uui-footer07_link-list">
              <Link
                to="/aboutus"
                aria-current="page"
                className="uui-footer07_link w-inline-block w--current"
              >
                <div>About US</div>
              </Link>
              <Link
                to="/privacypolicy"
                className="uui-footer07_link w-inline-block"
              >
                <div>Privacy Policy</div>
              </Link>
              <Link
                to="/terms&condition"
                className="uui-footer07_link w-inline-block"
              >
                <div>Terms & Conditions</div>
              </Link>
              <Link
                to="/cancle&refund"
                className="uui-footer07_link w-inline-block"
              >
                <div>Cancellation and Refund Policy</div>
              </Link>
              <Link
                to="/securitypolicy"
                className="uui-footer07_link w-inline-block"
              >
                <div>Security Policy</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
